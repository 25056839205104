<template>
  <v-app>
    <v-col cols="11" class="mx-auto">
      <div class="px-8 pt-8 pb-2 jumbotron">
        <h5 class="display-1">Vet Claims</h5>
        <p class="mt-4">Vet claims within this batch</p>
        <br />
        <v-row>
          <v-col cols="3" class="pb-0">
            <v-select
              :items="['Batch Name', 'Date', 'Provider', 'Total Claims', 'Clearance Status']"
              label="Filter by period"
              segmented
              overflow
              :editable="false"
              outlined
              color="green"
              target="#target"
            ></v-select>
          </v-col>
        </v-row>
      </div>
      <div class="my-5 py-5">
        <strong>Select a batch to vet individual claims</strong>
        <v-simple-table class="mt-5">
          <template v-slot:default>
            <thead class="text-capitalize py-4">
              <tr>
                <th>S/No</th>
                <th>Batch Name</th>
                <th>Date Received</th>
                <th>Provider</th>
                <th>Claims</th>
                <th>Total Claimed(NGN)</th>
                <th>Clearance Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(batch, index) in batches" :key="batch.id">
                <td>{{ index + 1 }}</td>
                <td>{{ batch.name }}</td>
                <td>{{ batch.date_created }}</td>
                <td>{{ batch.created_by }}</td>
                <td>{{ batch.claims.length }}</td>
                <td>{{totalClaimed(batch.claims)}}</td>
                <td>{{ batch.claims.filter(claim => claim.completed).length }} completed</td>
                <td>
                  <v-btn
                    :to="`/claims-processing/vetting/batches/${batch.id}/claims`"
                    medium
                    color="green darken-4 white--text"
                  >
                    <small class="font-weight-bold">Open</small>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-col>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "vetted-batch-list",
  computed: mapGetters({ batches: "claimModule/batches" }),
  methods: {
    totalClaimed(value) {
      //computes the total amount claimed based on claims present within a batch
      if (value.length > 1) {
        return value.reduce(
          (current, next) => current.amount_claimed + next.amount_claimed
        );
      } else if (value.length === 1) {
        return value[0].amount_claimed;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped>
.position {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
.anchor-color {
  color: white;
}
</style>
